import React from "react"
import { Link } from "gatsby"

const LanguageSelector = ({ lang, location, className }) => {
  const langs = [];
  if (lang !== "en") {
    langs.push(
      <Link key={'en'} className={className} to={`/en${location.pathname}`}>
        English
      </Link>
    )
  }
  // if (lang !== "es") {
  //   langs.push(
  //     <Link className={className} to={`/es${location.pathname}`}>
  //       Castellano
  //     </Link>
  //   )
  // }
  if (lang !== "default" && lang !== "ca") {
    langs.push(
      <Link
        key={'ca'}
        className={className}
        to={location.pathname.replace("/" + lang + "/", "/")}
      >
        Català
      </Link>
    )
  }
  return (
    <div className='language-selector'>
      <span className="icon fa-language" style={{ color: '#000', marginRight: '10px' }} />
      {langs}
    </div>
  )
}
export default LanguageSelector;