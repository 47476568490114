import React from 'react'
const labelStyle = {
  marginRight: '15px'
}
const Footer = ({ location, lang }) => (
  <div id="footer">
    <div className="inner">
      <div className='custom-footer'>
        <div>
          <a href="https://goo.gl/maps/ENA4TwfG5bDYs5NE6" style={labelStyle}><span >Granollers</span></a>
          <a href="https://goo.gl/maps/ENA4TwfG5bDYs5NE6" className="icon fa-map-marker" style={{ marginRight: '3px' }}><span className="label">Granollers</span></a>
        </div>
        <div>
          <a href="mailto:musicoterapia@saracillan.com" style={labelStyle}><span >musicoterapia@saracillan.com</span></a>
          <a href="mailto:musicoterapia@saracillan.com" className="icon fa-envelope-o"><span className="label">Email</span></a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/sara-cill%C3%A1n-grau-47359b169/" style={labelStyle}><span >LinkedIn</span></a>
          <a href="https://www.linkedin.com/in/sara-cill%C3%A1n-grau-47359b169/" className="icon fa-linkedin"><span className="label">LinkedIn</span></a>
        </div>
      </div>
      <ul className="copyright">
        <li>&copy; Sara Cillan Grau</li>
      </ul>
    </div>
  </div>
)

export default Footer
