import React from 'react'
import Helmet from 'react-helmet'
import Header from './Header'
import '../assets/scss/main.scss'
import LanguageSelector from './LanguageSelector'
import BannerManager from './BannerManager'

const siteTitle = 'Sara Cillan'
const siteDescription = 'Personal site'

const Layout = ({ children, isMain, location, lang }) => (
  <div>
    <BannerManager lang={lang} />
    <Helmet>
      <title>{siteTitle}</title>
      <meta name="description" content={siteDescription} />
    </Helmet>
    <Header location={location} lang={lang} />
    <div id="main">
      {children}
      {!isMain && (
        <ul className="actions" style={{ marginTop: '100px' }}>
          <li>
            <a href={(lang !== "default" && lang !== "ca") ? `/${lang}/` : "/"} className="button">
              {(lang === "default" || lang === "ca") ? 'Torna a l\'inici' : 'Home'}
            </a>
          </li>
        </ul>
      )}
      <LanguageSelector location={location} lang={lang} />
    </div>
  </div>
)

export default Layout;
