import React from 'react';

const BannerManager = ({ lang }) => {
  return (
    <div style={{
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      height: 41,
      backgroundImage: 'linear-gradient(to right, #c6ffdd, #fbd786)',
      zIndex: 99,
      textAlign: 'center',
      color: '#000',
      padding: 5,
      fontSize: '16px',
      lineHeight: '16px'
    }}>
      {lang === 'ca' ? 'Consulta els pròxims tallers' : 'Check the upcoming events'}
      <a
        className="button special small"
        style={{ marginLeft: '15px' }}
        href='/events'
      >
        {lang === 'ca' ? 'Aquí!' : 'Here!'}
      </a>
    </div>
  );
}


export default BannerManager;
